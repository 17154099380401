/* Theme color vars */
@accentColor: rgba(3, 124, 160, 1);
@accentColor80: rgba(3, 124, 160, 0.8);
@accentColor70: rgba(3, 124, 160, 0.7);
@accentColor50: rgba(3, 124, 160, 0.5);
@accentColor40: rgba(3, 124, 160, 0.4);
@accentColor30: rgba(3, 124, 160, 0.3);
@accentColor20: rgba(3, 124, 160, 0.2);
@accentColor10: rgba(3, 124, 160, 0.1);
@accentColor5: rgba(3, 124, 160, 0.05);
@accentColor3: rgba(3, 124, 160, 0.03);
@accentColor70Solid: #4FA4BD;
@accentColor10Solid: #E6F2F6;
@accentColor5Solid: #F2F8FA;
@accentHEX: #037CA0;

body {
  &.modern-theme {
    #base {
      #content {
        .section-body {
          .contain-lg {
            #formio-form-wizard {
              .formio-form {
                .wizard-page {
                  .formio-component {
                    :is(label, label.col-form-label):not(.form-check-label) {
                      color: @darkTextColor !important;
                      font-size: 16px !important;
                      font-weight: 400 !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}